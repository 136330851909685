// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$.me = $.me || {};

import {i18n} from "../locales/i18n"
import "swiper/swiper-bundle.min.css"
import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet-routing-machine/dist/leaflet-routing-machine.css"
import "leaflet-easybutton/src/easy-button.css"
import Modernizr from "modernizr";

global.I18n = i18n
global.$ = jQuery

import { Turbo } from '@hotwired/turbo-rails'
Turbo.start()

// import Confirm from '/libs/confirm'
// Confirm.railsSetup()

import 'turbo_streams'

// Library
// require('lib/fingerprint')
require("lib/me/core")
require("lib/me/jquery-ext")

require("lib/me/open-map")
require("lib/me/geolocation")

require("lib/me/video-player")

require("lib/modal")
require("lib/city")

// Open street map
require("leaflet")
require("leaflet.markercluster")
// require('leaflet-routing-machine')
// require('leaflet-easybutton')

// External main js
require("config")
require("main-external")

import 'controllers'

const meControllers = require.context("me_controllers/external")
meControllers.keys().forEach(meControllers)

import userConsent from 'libs/user_consent'
userConsent.start()

Modernizr.addTest('gap', () => {
  const element = document.createElement('div')
  element.style.display = 'flex'
  element.style.gap = '1px'
  return element.style.gap === '1px'
})

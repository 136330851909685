#
# Select city_id by zip code
#
import Toast from 'libs/toast'

$.extend $.me, {
  selectCityByZip: class
    default:
      update: false

    constructor: (node, opts) ->
      @_zip = $(node)
      if @_s('city-block').find('select > option').length > 1
        @_s('city-block').addClass 'city-block--is-visible'

      @_s('country').on 'change', => @_countryChanged()
      @_zip.on 'keyup', => @_checkZip()

      @_zipChanged() if opts.update and @_zip.val() != ''

    _s: (f) -> $("[data-city-by-zip=\"#{f}\"]")

    _zipChanged: ->
      if @_zip.val()
        @_zip.blur()
        $.get @_zip.data('url'), { country_id: @_s('country').val(), zip_code: @_zip.val() }, (data) =>
          # @_s('city-block').find('.subscribe-error-message').hide()
          @_s('city-block').find('select').trigger 'change'
          s_val = @_s('city-block').find('select').val()
          s = @_s('city-block').find('select').empty()
          s.toggleClass('one-option', data.length <= 1)
          s.append $('<option />').val(null).text(if data.length == 0 then I18n.t('js.me_location.unknow') else I18n.t('common.choose')) if data.length == 0 or data.length > 1
          if data.length > 0
            s.append($('<option />').val(d.id).text(d.name)) for k, d of data
            s.removeAttr('tabindex')
            s.val(s_val) if s_val != '' && @_s('city-block').find("select option[value=#{s_val}]").exists()
          else
            @_s('zip-code').focus().select()
            s.attr 'tabindex', '-1'

          if @_s('region').exists() and d?.region
            @_s('region').html d.region.name
          else
            @_s('region').html I18n.t('js.me_location.unknow')

          if @_s('county').exists() and d?.county
            @_s('county').html d.county.name
          else
            @_s('county').html I18n.t('js.me_location.unknow')

          # s.trigger 'change'
          @_s('city-block').addClass 'city-block--is-visible'

      else
        @_reset()

    _countryChanged: ->
      @_reset()

    _checkZip: ->
      if @_zip.val() == ''
        @_reset()
      else
        mask = new RegExp(@_s('country').find(':selected').data('zip-code-mask'))
        if @_zip.val().match(mask)
          @_zipChanged()
        else if @_zip.val().length is 5
          new Toast(I18n.t('js.me_location.unknow'), { type: 'alert' }).show()
          @_reset()

    _reset: ->
      opts = @_s('country').find(':selected').data('me')
      @_zip.val('').blur().attr 'maxlength', opts.zipsize
      @_s('city-block').removeClass('city-block--is-visible').find('select').empty().append $('<option />').val(null)
      @_s('city-block').find('.select').addClass('one-option')
      @_zip.focus()
      return

  searchByLocation: class
    _countrySelectSelector: 'select[data-me=country-select]'
    _regionSelectSelector: 'select[data-me=region-select]'
    _countySelectSelector: 'select[data-me=county-select]'

    constructor: (node) ->
      @_container = $(node)
      $(@_countrySelectSelector, @_container).on 'change', (e) => @_countryChanged($(e.target), @_container)
      $(@_regionSelectSelector, @_container).on 'change', => @_regionChanged(@_container)
      $(@_regionSelectSelector, @_container).on 'me:selectEmpty', (e) => @_populateSelect($(e.target), {id: $(@_countrySelectSelector, @_container).val()})
      $(@_countySelectSelector, @_container).on 'me:selectEmpty', (e) => @_populateSelect($(e.target), {id: $(@_regionSelectSelector, @_container).val()})

      if $(@_countrySelectSelector, @_container).find(':selected').exists()
        if $('[data-mobile]').length == 0
          $(@_countySelectSelector, @_container).parent().toggle $(@_countrySelectSelector, @_container).find(':selected').data('me')?.county

    _countryChanged: ($elmt, container) ->
      @_reset @_regionSelectSelector, container
      @_reset @_countySelectSelector, container
      if $('[data-mobile]').length == 0
        $(@_countySelectSelector, @_container).parent().toggle $elmt.find(':selected').data('me').county

    _regionChanged: (container) ->
      @_reset @_countySelectSelector, container

    _reset: (node, container) ->
      $(node, container).empty().append $('<option />').val(null).text(I18n.t('common.choose'))
      $(node, container).trigger 'me:selectEmpty'

    _populateSelect: (node, datas) ->
      $.get node.data('url'), datas, (data) =>
        if data.length > 0
          node.append($('<option />').val(d.id).text(d.name)) for k, d of data
          node.prop 'disabled', false
        else
          node.prop 'disabled', true

        node.trigger 'location:populate', [data.length]

  citiesAutocomplete: class
    _datalist: {}
    _default: url: '/cities/autocomplete/'

    constructor: ($input, options) ->
      $input.on 'input', (e) =>
        if $(e.target).val().length > 3
          unless data = @_datalist[$input.val()]
            $.getJSON "#{options.url}#{$input.val()}", (data) =>
              $("datalist##{$input.attr('list')}").empty()
              @_datalist = {}

              $.each data, (key, data) =>
                @_datalist[data.label] = data
                $('<option>').val(data.label).appendTo $("datalist##{$input.attr('list')}")
          else
            options.onChange.apply($input, [data]) if options.onChange
}

$.fn.selectCityByZip = (options) ->
  opts = $.extend true, {}, $.me.selectCityByZip.default, options
  new $.me.selectCityByZip($(@).first(), opts) if $(@).length > 0

$.fn.searchByLocation = ->
  new $.me.searchByLocation($(@).first()) if $(@).length > 0

$.fn.citiesAutocomplete = (options) ->
  opts = $.extend true, {}, $.me.citiesAutocomplete.default, options
  new $.me.citiesAutocomplete($(@), opts)
  $(@)

###
* Me API :: Video player instanciation
###

$.extend true, $.me,
  videoplayer:
    player_selector: '#video-player[data-me-video-plugin-attached=off]'

    init: (nodes) ->
      $(nodes).each (idx, elt) =>
        $('video', $(elt)).not('[data-me-video-plugin-attached="on"]').on 'play', ->
          # Stop other video playing
          $('*[data-me-video-plugin-attached="on"] video').not($(this)).each (i) ->
            $(this).get(0).pause()

        $(elt).attr 'data-me-video-plugin-attached', 'on'

$.fn.videoPlayer = ->
  $.me.videoplayer.init $(@) if $(@).length > 0

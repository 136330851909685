import noUiSlider from 'nouislider'

$.me = $.me || {}
$.extend true, $.me,
  openMap:
    url: 'https://maps.nouslib.com/osm/{z}/{x}/{y}.png'
    routingContainer: '.leaflet-routing-container'
    map:
      keepMap: true
      zoom: 9
      zoomControl: false
      attributionControl: false
      center:
        lat: 0
        lng: 0
      circle: color: '#ee2654'
      markers: {}
      buttons: [ {
        title: null
        html: ''
      } ]
      direction:
        routingUrl: 'https://maps.nouslib.com/route/v1'
        language: 'fr'
        profile: 'driving'
        options:
          collapsible: false
          show: true
          itineraryContainer: null

    init: ($container, options) ->
      centerPoint = undefined
      circle = undefined
      map = undefined
      map_options = undefined
      routeControl = undefined
      waypoints = undefined
      centerPoint = [
        options.center.lat
        options.center.lng
      ]
      map = null
      circle = null
      map_options =
        zoomControl: options.zoomControl
        attributionControl: options.attributionControl

      $.extend this,
        show: ->
          $container.show()
          if map == null
            map = L.map($container.get(0), map_options).setView(centerPoint, options.zoom)
            L.tileLayer($.me.openMap.url, id: $container).addTo map
          this

        circle: (radius) ->
          if circle == null
            circle = L.circle(centerPoint,
              radius: radius * 1000
              color: options.circle.color)
            circle.addTo map
          else
            circle.setRadius radius * 1000
          map.fitBounds circle.getBounds()
          this

        setLatLng: (lat, lng) ->
          centerPoint = [
            lat
            lng
          ]
          this

        clear: ->
          if map != null
            map.remove()
          map = null
          circle = null
          this

        markers: (markers) ->
          fitBoundsMarkers = undefined
          group = undefined
          fitBoundsMarkers = []

          $.each markers, (key, value) ->
            icon = undefined
            mk = undefined
            if value.options.icon
              icon = L.icon(
                iconUrl: value.options.icon.iconUrl
                iconSize: value.options.icon.iconSize
              )
              value.options.icon = icon

            mk = L.marker([
              value.lat
              value.lng
            ], $.extend(value.options, html: null)).addTo(map)

            if value.options.title and value.options.title != ''
              mk.bindTooltip value.options.title

            if value.options.url and value.options.url != ''
              mk.on 'click', ->
                if mk.options.url and mk.options.url != ''
                  if !mk.options.html and !mk.options.url_follow
                    $.get mk.options.url, (html) ->
                      mk.options.html = html
                      mk.bindPopup(mk.options.html).openPopup()
                  else
                    location.href = mk.options.url

            fitBoundsMarkers.push mk

          group = new (L.featureGroup)(fitBoundsMarkers)
          map.fitBounds group.getBounds()
          this

        buttons: (buttons) ->
          $.each buttons, (idx, button) ->
            L.easyButton(button.html, ((btn, map) ->
              window.open $(btn.button).find('a').attr('href')

            ), button.title).addTo map

          this
        direction: (direction) ->
          `var routeControl`
          opts = $.extend(true, {}, $.me.openMap.map.direction, direction)

          routeControl = (opts, waypoints) ->
            fitBoundsMarkers = []

            L.Routing.control(
              waypoints: waypoints
              router: new (L.Routing.osrmv1)(
                serviceUrl: opts.routingUrl
                language: opts.language
                profile: opts.profile)

              createMarker: (i, wp, nWps) ->
                icon = undefined
                mkr = undefined

                if opts.markers
                  if i == 0 or i == nWps - 1
                    icon = L.icon(
                      iconUrl: opts.markers[i].options.icon.iconUrl
                      iconSize: opts.markers[i].options.icon.iconSize
                    )
                    mkr = L.marker(wp.latLng, icon: icon)
                    fitBoundsMarkers.push mkr
                    return mkr
                  else
                    icon = L.icon(
                      iconUrl: opts.markers[1].options.icon.iconUrl
                      iconSize: opts.markers[1].options.icon.iconSize
                    )
                    mkr = L.marker(wp.latLng, icon: icon)
                    fitBoundsMarkers.push mkr
                    return mkr
                this
              collapsible: opts.options.collapsible
              show: opts.options.show
              addWaypoints: false
            ).on('routeselected', (e) ->
              if opts.options.itineraryContainer != null
                $(opts.options.itineraryContainer).empty()
                $(e.target._container).detach().appendTo opts.options.itineraryContainer
              if fitBoundsMarkers.length
                group = L.featureGroup(fitBoundsMarkers).addTo(map)
                map.fitBounds group.getBounds()
              return
            ).addTo map

          if opts.from.lat and opts.to.lat
            routeControl opts, [
              L.latLng([
                opts.from.lat
                opts.from.lng
              ])
              L.latLng([
                opts.to.lat
                opts.to.lng
              ])
            ]
          else
            waypoints = []
            if opts.from.lat
              waypoints.push L.latLng([
                opts.from.lat
                opts.from.lng
              ])
              if opts.to.lat
                waypoints.push L.latLng([
                  opts.to.lat
                  opts.to.lng
                ])
                $($container).trigger 'openmap:waypoints'
              else
                $.getJSON '' + $.me.openMapNominatim['default'].url + opts.to.address, (data) ->
                  waypoints.push L.latLng([
                    data[0].lat
                    data[0].lon
                  ])
                  $($container).trigger 'openmap:waypoints'
            else
              $.getJSON '' + $.me.openMapNominatim['default'].url + opts.from.address, (data) ->
                waypoints.push L.latLng([
                  data[0].lat
                  data[0].lon
                ])
                if opts.to.lat
                  waypoints.push L.latLng([
                    opts.to.lat
                    opts.to.lng
                  ])
                  $($container).trigger 'openmap:waypoints'
                else
                  return $.getJSON('' + $.me.openMapNominatim['default'].url + opts.to.address, (data) ->
                    waypoints.push L.latLng([
                      data[0].lat
                      data[0].lon
                    ])
                    $($container).trigger 'openmap:waypoints'
                  )

          $($container).on 'openmap:waypoints', (e) ->
            routeControl opts, waypoints

          this

  openMapSlider:
    'default':
      context: ''
      slider:
        value: 0
        min: 0
        max: null
        step: 10
      field: '#search_km_radius'
      canvas: null
      autocomplete: null
      hidding:
        auto: true
        min: true
        max: true
      beforeSlide: null

    init: (container, opts) ->
      conf = opts
      self = this
      noUiKm = $(container).get(0)
      min = conf.slider.min or 0
      max = conf.slider.max or 200
      slider_map = null

      distanceFormater = (v) ->
        s_distance = ''
        if v == min
          s_distance = I18n.t('js.gps.my_city')
        if v > min and v < max
          s_distance = v + 'Km'
        if v == max
          s_distance = I18n.t('js.search.everywhere')
        s_distance

      noUiSlider.create noUiKm,
        start: [conf.slider.value]
        connect: [true, false]
        step: 5
        range:
          'min': [min]
          'max': [max]
        pips:
          mode: 'count'
          values: 5
          density: 5
          format: to: distanceFormater

      noUiKm.noUiSlider.on 'update', (value, handle) ->
        val = undefined
        val = Math.round(value[handle])
        $(conf.field).val Math.round(value[handle])
        $(@target, container).find('.noUi-handle').eq(handle).attr 'data-label', Math.round(value[handle])

        if val > min and val < max
          $(@target, container).find('.noUi-handle').eq(handle).addClass 'km'
          $(@target, container).find('.noUi-handle').eq(handle).removeClass 'home infinite'
        else if val == max
          $(@target, container).find('.noUi-handle').eq(handle).addClass 'infinite'
          $(@target, container).find('.noUi-handle').eq(handle).removeClass 'home km'
          $(conf.field).val ''
          $('div[data-me-map-canvas]', container).hide()
        else if val == min
          $(@target, container).find('.noUi-handle').eq(handle).addClass 'home'
          $(@target, container).find('.noUi-handle').eq(handle).removeClass 'km infinite'
          $('div[data-me-map-canvas]', container).hide()

      noUiKm.noUiSlider.on 'slide', (value, handle) ->
        val = undefined
        val = Math.round(value[handle])
        if slider_map and val > min and val < max
          slider_map.show().circle parseInt(val)

      noUiKm.noUiSlider.on 'end', ->
        if opts.canvas
          $(opts.canvas, container).hide()
        $(container).trigger 'mapslider-update'

      $.extend this,
        initMap: ->
          canvas_option = undefined
          map_options = undefined
          canvas_option = $(opts.canvas).data('me-map-canvas')
          if canvas_option
            map_options =
              keepMap: false
              center:
                lat: canvas_option.center.lat
                lng: canvas_option.center.lng
            if $(opts.canvas, container).exists()
              slider_map = $(opts.canvas, container).openMap(map_options)
          this
        clearMap: ->
          slider_map.clear() if slider_map
          this
        reset: ->
          noUiKm.noUiSlider.reset()
          this
        update_map: ->
          slider_map.show() if slider_map
          this
        setSlider: (value) ->
          noUiKm.noUiSlider.set value
          this
        getSlider: ->
          noUiKm.noUiSlider.get()
        updateOptions: (options) ->
          noUiKm.noUiSlider.updateOptions options

      self.initMap()

  openMapNominatim:
    'default':
      url: 'https://maps.nouslib.com/nominatim/search.php?format=json&addressdetails=1&dedupe=1&countrycodes=fr,be,ch,lu&q='
      datalist_url: 'https://maps.nouslib.com/nominatim/search.php?format=json&addressdetails=1&dedupe=1&countrycodes=fr,be,ch,lu&q='
      search_type: 'town'

    init: ($input, options) ->
      resultFormater = undefined
      datalist = {}

      resultFormater = (place) ->
        postcode = undefined
        town = undefined

        if options.search_type == 'town'
          if !(place.type == 'city' or place.type == 'town' or place.type == 'village' or place.type == 'administrative')
            return null

          town = if place.address.city != null then place.address.city else if place.address.town != null then place.address.town else place.address.village
          postcode = if place.address.postcode != null then ' ' + place.address.postcode else ''

          if town != null
            return '' + town + postcode + ', ' + place.address.state + ', ' + place.address.country
          else
            return null
        else
          return place.display_name

      $input.blur (e) ->
        data = datalist[$input.val()]

        unless data
          $.getJSON "#{options.url}#{$(@).val()}", (data) ->
            if data[0] and options.onChange
              options.onChange.apply $input, [data[0].lat, data[0].lon]
            else
              return data
        else
          options.onChange.apply($input, [data.lat, data.lon]) if options.onChange

      if $input.attr('list') and $("[list=#{$input.attr('list')}]").exists()
        # HTLML 5 datalist autocomplete
        $input.on 'input', (e) ->
          if $(e.target).val().length > 3
            unless data = datalist[$input.val()]
              $.getJSON "#{options.url}#{$(this).val()}", (data) ->
                $("datalist##{$input.attr('list')}").empty()
                datalist = {}

                $.each data, (key, data) ->
                  key = resultFormater(data)
                  datalist[key] = data
                  $('<option>').val(key).appendTo $("datalist##{$input.attr('list')}")
            else
              options.onChange.apply($input, [data.lat, data.lon]) if options.onChange

$.fn.extend openMap: (options) ->
  map = undefined
  opts = undefined
  opts = $.me.openMap.map
  opts = $.extend(true, {}, opts, options)

  if opts.keepMap and $(@).data('me-map-instance')
    return $(@).data('me-map-instance')

  map = new ($.me.openMap.init)($(@), opts)
  $(@).data 'me-map-instance', map

  map

$.fn.extend openMapSlider: (options) ->
  ms = undefined
  opts = undefined
  ref = undefined
  ms = @eq(if (ref = typeof options == 'number') != null then ref else options: 0).data('me-mapslider-instance')
  if ms
    return ms
  opts = $.extend(true, $.me.openMapSlider['default'], options, $(@).data('me-mapslider'))
  if @selector == 'document'
    ms = new ($.me.openMapSlider.init)(null, opts)
    return ms
  else
    $(@).each ->
      ms = new ($.me.openMapSlider.init)($(@), opts)
      $(@).data 'me-mapslider-instance', ms
  ms

$.fn.extend openMapNominatim: (options) ->
  nominatim = undefined
  opts = undefined
  opts = $.me.openMapNominatim['default']
  opts = $.extend(true, {}, opts, options)

  if $(@).data('me-nominatim-instance')
    return $(@).data('me-nominatim-instance')

  nominatim = new ($.me.openMapNominatim.init)($(@), opts)
  $(@).data 'me-nominatim-instance', nominatim

  nominatim

# End of openMap */

import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
$.extend true, $.me,
  pages:
    home_act: ->
      Swiper.use([Autoplay, Pagination, EffectFade]);

      new Swiper('.js-slider-classic', {
        pagination: {
          el: ".js-slider-classic-pagination",
          clickable: true,
        },
        slidesPerView: 1,
        centeredSlides: false,
        spaceBetween: 15,
      });

      intersectionHandler = (entries, observer) ->
        for entry in entries
          threshold = parseFloat(entry.target.getAttribute('data-threshold'))
          if entry.intersectionRatio > threshold
            entry.target.classList.remove('animation--start')
            observer.unobserve(entry.target)

      observer = new IntersectionObserver(intersectionHandler, { threshold: Array.from({length: 11}, (x, i) -> i * 0.1 ) })

      elmtsToAnim = document.querySelectorAll('[data-controller="animate-on-entering-viewport"]')
      elmtsToAnim.forEach((elmt) ->
        elmt.classList.add('animation--start')
        observer.observe(elmt)
      )

      $('#cookies_policy').click (e) ->
        e.preventDefault()
        $(e.target).closest('div.cookies').remove()
        document.cookie = 'cookies_policy=on'

      # TODO : Clean this shit ...
      $(document).on 'me:domChanged', (e) ->
        $('#disclaimer-button').click (e) ->
          e.preventDefault()
          day = $('#days').val()
          month = $('#months').val()
          year = $('#years').val()
          birth_date = new Date(year, month, day)

          birth_date.setFullYear(birth_date.getFullYear() + 18)

          if year && birth_date <= new Date()
            $('#disclaimer-button').attr("data-modal", "close")

          else if year == ""
            $('#disclaimer-error-message').removeClass('display-none')
          else
            location.href = 'https://www.google.fr'

    prelaunch_act: ->

      Swiper.use([Autoplay, Pagination, EffectFade]);

      new Swiper('.js-slider-classic', {
        pagination: {
          el: ".js-slider-classic-pagination",
          clickable: true
        },
        slidesPerView: 1,
        centeredSlides: false,
        spaceBetween: 15
      })

      intersectionHandler = (entries, observer) ->
        for entry in entries
          threshold = parseFloat(entry.target.getAttribute('data-threshold'))
          if entry.intersectionRatio > threshold
            entry.target.classList.remove('animation--start')
            observer.unobserve(entry.target)

      observer = new IntersectionObserver(intersectionHandler, { threshold: Array.from({length: 11}, (x, i) -> i * 0.1 ) })

      elmtsToAnim = document.querySelectorAll('[data-controller="animate-on-entering-viewport"]')
      elmtsToAnim.forEach((elmt) ->
        elmt.classList.add('animation--start')
        observer.observe(elmt)
      )

      $('#cookies_policy').click (e) ->
        e.preventDefault()
        $(e.target).closest('div.cookies').remove()
        document.cookie = 'cookies_policy=on'

      # TODO : Clean this shit ...
      $(document).on 'me:domChanged', (e) ->
        $('#disclaimer-button').click (e) ->
          e.preventDefault()
          day = $('#days').val()
          month = $('#months').val()
          year = $('#years').val()
          birth_date = new Date(year, month, day)

          birth_date.setFullYear(birth_date.getFullYear() + 18)

          if year && birth_date <= new Date()
            $('#disclaimer-button').attr("data-modal", "close")

          else if year == ""
            $('#disclaimer-error-message').removeClass('display-none')
          else
            location.href = 'https://www.google.fr'

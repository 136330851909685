#-----------------------------------------------#
# JQuery utilities                              #
#-----------------------------------------------#
import Modernizr from 'modernizr'

$.fn.extend
  # Force a dom render to have proper transitions
  forceDomRendering: ->
    @get(0).clientHeight
    @

  # JQuery findIncludeSelf
  findIncludeSelf: (selector) -> @find(selector).addBack(selector)

  # TRUE if dom elements of selector exists
  exists: ->
    @length > 0

  # Block right click context menu
  nocopy: (options) ->
    $(this).on 'contextmenu', $(this), (e) ->
      if e.target.nodeName != 'INPUT' and e.target.nodeName != 'TEXTAREA'
        e.preventDefault()
        if options and options.callback
          options.callback e

    if typeof Modernizr == 'function'
      $(this).css Modernizr.prefixed('userSelect'), 'none'
    else
      $(this).css 'MozUserSelect': '-moz-none'

  # Put cursor at the end of text in textarea
  putCursorAtEnd: ->
    return this.each ->
      $(this).focus()

      # If this function exists...
      if this.setSelectionRange
        # ... then use it
        # (Doesn't work in IE)
        # Double the length because Opera is inconsistent about whether a carriage return is one character or two. Sigh.
        len = $(this).val().length * 2
        this.setSelectionRange len, len
      else
        # ... otherwise replace the contents with itself
        # (Doesn't work in Google Chrome)
        $(this).val $(this).val()

      # Scroll to the bottom, in case we're in a tall textarea
      # (Necessary for Firefox and Google Chrome)
      this.scrollTop = 999999;

  # Helper function which checks for blank inputs in a form that match the specified CSS selector
  blankInputs: (options) ->
    opts = $.extend({}, {
      selector: 'input[data-me-required]:not([disabled]),textarea[data-me-required]:not([disabled])',
      blank: true
    }, options)

    inputs = $()
    input = undefined
    $(@).find(opts.selector).each ->
      input = $(@)

      # Collect non-blank inputs if blank option is false, otherwise, collect blank inputs
      if !opts.blank and input.val()
        inputs = inputs.add(input)
      else
        if input.attr('placeholder')
          if !input.val() or input.val() == input.attr('placeholder')
            inputs = inputs.add(input)
        else
          if !input.val()
            inputs = inputs.add(input)

    if inputs.length then inputs else false

  # Infinite pagination
  infiniteScrollPagination: (options = {}) ->
    _selector: '[data-paginate]'

    _paginate = (elmt, opts) ->
      $(elmt).on 'scroll.paginate', (e) ->
        if !elmt.data('lock') && $(window).scrollTop() + $(window).height() - elmt.offset().top - elmt.outerHeight(true) >= 0
          elmt.data('lock', true).addClass('loader--is-shown')
          elmt.data 'page', (parseInt(elmt.data 'page') || 1) + 1

          datas = {page: elmt.data('page')}
          datas = opts.beforeScroll(datas) if opts.beforeScroll

          $.get elmt.data('paginate'), datas, (data) ->
            if data.trim() != ''
              if elmt.data('append')
                $(elmt.data('append')).append(data)
              else
                elmt.append(data)
              elmt.data('lock', false)

            elmt.removeClass('loader--is-shown')

            opts.onLoaded(elmt, data) if opts.onLoaded

    _opts = $.extend { beforeScroll: null, onLoaded: null }, options
    return @each -> _paginate($(this), _opts)

  # Autocomplete with html5 <datalist> tag
  autocomplete: (options) ->
    opts =
      minLength: 3
      source: null
      onChange: null

    @datas = {}

    @options = $.extend(true, {}, opts, options)

    $(@).on 'input', (e) =>
      if $(e.target).val().length > @options.minLength and !@datas[$(e.target).val()]
        $.getJSON @options.source, term: $(@).val(), (datas) =>
          $("datalist##{$(@).attr('list')}").empty()
          @datas = {}
          $.each datas, (key, data) =>
            @datas[data.label] = data
            $('<option>').val(data.label).appendTo $("datalist##{$(@).attr('list')}")
      else if @datas[$(e.target).val()]
        options.onChange.apply($(@), [@datas[$(@).val()]]) if @options.onChange

    $(@).blur (e) =>
      options.onChange.apply($(@), [@datas[$(@).val()]]) if @options.onChange

    $(@)
